import React, { createContext, useReducer, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import AuthReducer from './AuthReducer';

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem("user")) || null,
  role: JSON.parse(localStorage.getItem("role")) || null, // Inicializa el rol desde localStorage
  showWelcome: true,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  console.log("Initial State:", INITIAL_STATE);

  const getUserRoleByEmail = async (email) => {
    const db = getFirestore();
    const rolesRef = collection(db, 'user_roles');
    const rolesQuery = query(rolesRef, where("email", "==", email));
    const querySnapshot = await getDocs(rolesQuery);
    return querySnapshot.empty ? null : querySnapshot.docs[0].data().rol;
  };

  useEffect(() => {
    console.log("Checking auth state...");
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User found:", user);
        const userRole = await getUserRoleByEmail(user.email);
        console.log("User role:", userRole);
        dispatch({ type: "LOGIN", payload: { user, role: userRole } });
        localStorage.setItem("user", JSON.stringify(user)); // Guarda el usuario en localStorage
        localStorage.setItem("role", JSON.stringify(userRole)); // Guarda el rol en localStorage
        console.log("User and role saved to localStorage");
      } else {
        console.log("No user found, logging out...");
        dispatch({ type: "LOGOUT" });
        localStorage.removeItem("user"); // Elimina el usuario de localStorage
        localStorage.removeItem("role"); // Elimina el rol de localStorage
        console.log("User and role removed from localStorage");
      }
    });

    return () => {
      console.log("Unsubscribing from auth state...");
      unsubscribe();
    };
  }, []);

  const hideWelcomeMessage = () => {
    dispatch({ type: "HIDE_WELCOME" });
  };

  return (
    <AuthContext.Provider value={{
      currentUser: state.currentUser,
      role: state.role,
      showWelcome: state.showWelcome,
      dispatch,
      hideWelcomeMessage
    }}>
      {children}
    </AuthContext.Provider>
  );
};
