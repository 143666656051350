import React from 'react';
import { useNavigate } from 'react-router-dom';
import notFoundImage from '../../components/imagenes/notfound.png';
import './NotFoundPage.scss'; // Asegúrate de que la ruta a tu archivo SCSS sea correcta

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="not-found-container">
      <img src={notFoundImage} alt="Page Not Found" className="not-found-image" />
      <button onClick={handleGoHome} className="go-home-button">
        Volver al Inicio
      </button>
    </div>
  );
};

export default NotFoundPage;

