const AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        currentUser: action.payload.user,
        role: action.payload.role,
        showWelcome: true, // Restablecer a true al iniciar sesión
      };
    case "LOGOUT":
      return {
        ...state,
        currentUser: null,
        role: null,
        showWelcome: false, // Opcionalmente, podrías ocultar el mensaje al cerrar sesión
      };
    case "HIDE_WELCOME": // Nuevo caso para ocultar el mensaje de bienvenida
      return {
        ...state,
        showWelcome: false
      };
    default:
      return state;
  }
};

export default AuthReducer;
