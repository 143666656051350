import React, { useEffect, useContext } from 'react';
import WelcomePopup from "../../pages/WelcomePopup";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import './PanelGeneral.scss';
import Sidebar from "../../components/sidebar/Sidebar";

const PanelGeneral = ({ cliente }) => {
 const navigate = useNavigate();
 const { currentUser, role, showWelcome, hideWelcomeMessage } = useContext(AuthContext);
 const allowedRolesForMisParrillasSection = ["mkt", "misparrillas", "mariana", "cami", "natalia"];
 const allowedRolesForHabitatSection = ["mkt", "habitat", "mariana", "cami", "natalia"];
 const allowedRolesForVolverAEscucharSection = ["mkt", "volveraescuchar", "mariana", "cami", "natalia"];
 const allowedRolesForDraCignettiSection = ["mkt", "dracignetti", "mariana", "cami", "natalia"];
 const allowedRolesForBAOSection = ["mkt", "bao", "mariana", "cami", "natalia"];
 const allowedRolesForRedPagosSection = ["mkt", "redpagos", "mariana", "cami", "natalia"];
 const allowedRolesForTGCSection = ["mkt", "tgc", "mariana", "cami", "natalia"];
 const allowedRolesForPuntaBallenaSection = ["mkt", "puntaballena", "mariana", "cami", "natalia"];
 const allowedRolesForBenozziSection = ["mkt", "benozzi", "mariana", "cami", "natalia"];
 const allowedRolesForBeautySection = ["mkt", "mariana", "cami", "natalia", "beautyderm"];
 const allowedRolesForMoscowSection = ["mkt", "mariana", "cami", "natalia", "moscow"];
 const allowedRolesForMKTSection = ["mkt"];
 const allowedRolesForMDESection = ["mkt", "mariana", "cami", "natalia"];

 useEffect(() => {
 if (!currentUser) {
 navigate("/login");
 }

 if (showWelcome) {
 setTimeout(() => hideWelcomeMessage(), 6000);
 }
 }, [currentUser, navigate, showWelcome, hideWelcomeMessage]); 


 return (
 <div className="general-panel">
<Sidebar cliente={cliente} />
 <div className="general-container">
 {cliente === "mde" && allowedRolesForMDESection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/c450aff2-e44e-4fc4-9eb9-26ea6fa10bca/page/yKJoD"
 frameBorder="0" 
 title="Looker MDE"
 ></iframe>
 </div> )}
 {cliente === "cursomktinmobiliario" && allowedRolesForMDESection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/3fb03381-98c3-4c86-a70f-e1e8b400006a/page/wyuOC"
 frameBorder="0" 
 title="Looker CMI"
 ></iframe>
 </div> )}

 {cliente === "misparrillas" && allowedRolesForMisParrillasSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/ee620dcf-141f-41fc-92a4-2d535a537305/page/yKJoD"
 frameBorder="0"
 title="Looker Misparrillas"
 ></iframe>
 </div>
 )}

 {cliente === "habitatgroup" && allowedRolesForHabitatSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/a6e60ddf-f97f-4d01-963f-b865b58db257/page/yKJoD"
 frameBorder="0"
 title="Looker Habitat"
 ></iframe>
 </div>
 )}
  {cliente === "EB-5" && allowedRolesForHabitatSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/3192e087-7c7b-44f5-8126-a9e36ee99c25/page/yKJoD"
 frameBorder="0"
 title="Looker EB-5"
 ></iframe>
 </div>
 )}
 {cliente === "smartbrickell" && allowedRolesForHabitatSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/40b7e81d-fa17-4ebb-a4d7-1c8b66dc21e5/page/yKJoD"
 frameBorder="0"
 title="Looker Smart Brickell"
 ></iframe>
 </div>
 )}
 {cliente === "smartbrickellhotel" && allowedRolesForHabitatSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/8fdc9591-fec8-4080-a04c-96091855d876/page/yKJoD"
 frameBorder="0"
 title="Looker Smart Brickell Hotel"
 ></iframe>
 </div>
 )}

 {cliente === "dracignetti" && allowedRolesForDraCignettiSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/4f7270a8-b4c1-47b7-9713-63d4afe065ae/page/yKJoD"
 frameBorder="0"
 title="Looker Dra Cignetti"
 ></iframe>
 </div>
 )}
  {cliente === "beautyderm" && allowedRolesForBeautySection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/5807c606-2d24-4ceb-8a01-2f2c54693859/page/yKJoD"
 frameBorder="0"
 title="Looker Beauty Derm"
 ></iframe>
 </div>
 )}
 {cliente === "moscow" && allowedRolesForMoscowSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/92830d69-fe43-4e91-acb9-e19ad8be9e93/page/yKJoD"
 frameBorder="0"
 title="Looker Moscow"
 ></iframe>
 </div>
 )}
  {cliente === "volveraescuchar" && allowedRolesForVolverAEscucharSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/21b98c10-c654-4d6c-8d7b-119cab4e20f9/page/yKJoD"
 frameBorder="0"
 title="Looker Volver a Escuchar"
 ></iframe>
 </div>
 )}

 {cliente === "baoworkcenter" && allowedRolesForBAOSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/302a8a91-7f5e-45e2-b79f-ff5dfddd0c62/page/yKJoD"
 frameBorder="0"
 title="Looker BAO"
 ></iframe>
 </div>
 )} 

 {cliente === "redpagos" && allowedRolesForRedPagosSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/e1b5fb62-c6dd-46c5-a9d3-128d8afbd760/page/yKJoD"
 frameBorder="0"
 title="Looker Red Pagos"
 ></iframe>
 </div>
 )}

 {cliente === "tgc" && allowedRolesForTGCSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/860272e7-10f3-466b-98fc-d3e278f5dcb4/page/yKJoD"
 frameBorder="0"
 title="Looker TGC"
 ></iframe>
 </div>
 )}

 {cliente === "puntaballena" && allowedRolesForPuntaBallenaSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/a2b1946e-67a8-42a2-b874-ab69204692d3/page/yKJoD"
 frameBorder="0"
 title="Looker Punta Ballena"
 ></iframe>
 </div>
 )}

 {cliente === "benozzi" && allowedRolesForBenozziSection.includes(role) && (
 <div className="general-container-general">
 <iframe className="looker-iframe-full"
 src="https://lookerstudio.google.com/embed/reporting/3285d977-7e19-4bb7-bef2-dcc9202268e4/page/yKJoD"
 frameBorder="0"
 title="Looker Benozzi"
 ></iframe>
 </div>
 )}

 {cliente === "mkt" && allowedRolesForMKTSection.includes(role) && (
 <div className="looker-grid">
 <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/83ccaa30-f98b-449a-a6f4-698d531a404e/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (1)"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/84a8f463-6775-4bd0-8614-2c731bac8145/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (2)"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/2114f2fe-5fba-4cf5-b71f-c65b8fa9e8c3/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (3)"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/31cd82ec-59f0-4e46-a340-672d2684d021/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (4)"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/379231a2-79c2-4c56-9c09-e828a008cf19/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (5)"
 ></iframe>
  <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/b3a19c58-fcba-4101-b73b-52c51b6c5d43/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (6)" //Nuevo agregar
 ></iframe>    
   <iframe className="looker-iframe-grid"
 width="1650" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/b77ecd6d-853d-4344-b82f-97c0a2107fb0/page/yKJoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker General Clientes (7)" //Nuevo agregar
 ></iframe>    
 </div>
 )} 

{cliente === "mkt" && role === "mariana" &&(
 <div className="general-container-general">
 <iframe className="looker-iframe-socials"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/cd7f08e3-19cc-4ba2-9249-5ce10427a4ee/page/yvf4D"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker clientes Mariana"
 ></iframe>
 </div>
 )} 
{cliente === "mkt" && role === "natalia" &&(
 <div className="general-container-general">
 <iframe className="looker-iframe-socials"
 src="https://lookerstudio.google.com/embed/reporting/71a660ef-5062-4642-a194-5eb0494ac6af/page/qxf4D"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker Clientes Natalia 1"
 ></iframe>
  <iframe className="looker-iframe-socials"
 src="https://lookerstudio.google.com/embed/reporting/d249c956-21e8-4cdf-9e5b-0a4a3c61f798/page/D4f4D"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker Clientes Natalia 2"
 ></iframe>
 </div>
 )} 
{cliente === "mkt" && role === "cami" &&(
 <div className="looker-grid-social">
 {/* iframes para el rol 'cami' */}
<iframe className="looker-iframe-grid"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/185c966e-1053-4dde-bae4-c0fa629d6fb9/page/WQXoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker Habitat"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/bcc039cf-bb4f-40e3-a664-6c482cbe5fb5/page/WQXoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker Smart Brikcell"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/3429422d-ddf0-4f65-ba88-27a817a6438b/page/WQXoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker Smart Brikcell Hotel"
 ></iframe>
 </div>
 )} 
 {/* Puedes agregar más condiciones para otros clientes si es necesario */}
 </div>

 {cliente === "mde" && role === "harold" &&(
 <div className="looker-grid-social">
 {/* iframes para el rol 'harold' */}
 <iframe className="looker-iframe-grid"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/3bd8e596-29bb-47f5-9333-7ed6bf1c52bd/page/WQXoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker MDE"
 ></iframe>
 <iframe className="looker-iframe-grid"
 width="1000" 
 height="700" 
 src="https://lookerstudio.google.com/embed/reporting/02a79f7c-d776-473d-ba6b-e96b84755a38/page/WQXoD"
 frameBorder="0" 
 style={{ border: '0' }} 
 title="Looker CMI"
 ></iframe>
 </div>
 )} 
 {showWelcome && <WelcomePopup message={`Bienvenido ${currentUser.email}`} />}
 </div>
 );
};


export default PanelGeneral;