import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ElegirCliente.scss';

const ElegirCliente = () => {
  const navigate = useNavigate();

  const handleClientSelection = (clientName) => {
    switch (clientName) {
      case "MKT Marketing Digital":
        navigate('/mkt/panelgeneral');
        break;
      case "MDE Marketing Digital Experience":
        navigate('/mde/panelgeneral');
        break;
      case "Mis Parrillas":
        navigate('/misparrillas/panelgeneral');
        break; 
      case "Red Pagos":
        navigate('/redpagoscavia/panelgeneral');
        break;
      case "Volver a Escuchar":
        navigate('/volveraescuchar/panelgeneral');
        break;
      case "BAO Workcenter":
        navigate('/baoworkcenter/panelgeneral');
        break;
      case "Dr. Jones":
        navigate('/drjones/panelgeneral');
        break;      
      case "Benozzi":
        navigate('/benozzi/panelgeneral');
        break;
      case "Punta Ballena":
        navigate('/puntaballena/panelgeneral');
        break;
      case "TGC":
        navigate('/tgc/panelgeneral');
        break;
      case "Habitat Group":
        navigate('/habitatgroup/panelgeneral');
        break;
      case "Dra Cignetti":
        navigate('/dracignetti/panelgeneral');
        break;
      case "Beauty Derm":
        navigate('/beautyderm/panelgeneral');
        break;
        case "Curso de MKT Inmobiliario":
        navigate('/cursomktinmobiliario/panelgeneral');
         break;
      case "Moscow":
        navigate('/moscow/panelgeneral');
        break;  
      // Agrega más casos para otros clientes si es necesario
      default:
        // Manejar casos no especificados si es necesario
        break;
    }
  };

  return (
    <div className="elegir-cliente-container">
      <h1>Seleccione un Cliente</h1>
      <div className="clientes-grid">
        <button onClick={() => handleClientSelection("MKT Marketing Digital")}>
          MKT Marketing Digital
        </button>
        <button onClick={() => handleClientSelection("MDE Marketing Digital Experience")}>
          MDE 
        </button>
        <button onClick={() => handleClientSelection("Habitat Group")}>
         Habitat Group
        </button>
        <button onClick={() => handleClientSelection("Mis Parrillas")}>
          Mis Parrillas
        </button>
        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>

      <div className="clientes-grid">
      <button onClick={() => handleClientSelection("Volver a Escuchar")}>
          Volver a Escuchar
        </button>
        <button onClick={() => handleClientSelection("Dra Cignetti")}>
         Dra. Cignetti
        </button>
        <button onClick={() => handleClientSelection("Beauty Derm")}>
          Beauty Derm
        </button>
        <button onClick={() => handleClientSelection("Benozzi")}>
         Benozzi
        </button>
        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>
          
      <div className="clientes-grid">
      <button onClick={() => handleClientSelection("BAO Workcenter")}>
          BAO Workcenter
        </button>
      <button onClick={() => handleClientSelection("Punta Ballena")}>
         Punta Ballena
        </button>
        <button onClick={() => handleClientSelection("TGC")}>
         TGC
        </button>
        <button onClick={() => handleClientSelection("Red Pagos")}>
          Red Pagos
        </button>

        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>
      
      <div className="clientes-grid">
      <button onClick={() => handleClientSelection("Moscow")}>
          Moscow
        </button>
        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>

      <div className="clientes-grid">

        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>
    </div>
  );
};

export default ElegirCliente;

