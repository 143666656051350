import React, { useContext} from 'react';
import { AuthContext } from '../../context/AuthContext';
import { logout } from '../../firebase.js';
import './Perfil.scss';
import Sidebar from "../../components/sidebar/Sidebar";
import profileImage from '../../components/imagenes/usuario-perfil.png';


const Perfil = () => {
  const { currentUser, dispatch } = useContext(AuthContext);

  const handleLogout = () => {
    logout()
      .then(() => {
        dispatch({ type: 'LOGOUT' });
      })
      .catch((error) => {
        console.error("Error al cerrar sesión: ", error);
      });
  };

  // Función para obtener la URL de la imagen de perfil con mayor resolución
  const getHighResImageUrl = (url) => {
    if (!url) return null;
    return url.replace(/=s\d+-c/, "=s300-c"); // Cambia el tamaño de la imagen a 300x300
  };

  return (
    <div className="perfil-panel">
      <Sidebar />
      <div className="perfil-container">
        {currentUser && (
          <div>
            <h1>Perfil de Usuario</h1>
            <img src={profileImage} alt="Foto de perfil" className="profile-image" />
            <p>Nombre: {currentUser.displayName}</p>
            <p>Email: {currentUser.email}</p>
          </div>
        )}
        <button className='cierre-sesion' onClick={handleLogout}>Cerrar sesión</button>
      </div>
    </div>
  );
};

export default Perfil;
