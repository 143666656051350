import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate desde react-router-dom
import { AuthContext } from "../../context/AuthContext"; // Asegúrate de que esta ruta sea la correcta
import './Equipo.scss'; // Asegúrate de que el nombre del archivo SCSS coincida
import Sidebar from "../../components/sidebar/Sidebar";

const Equipo = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  // Definición de los miembros del equipo
  const CEOMembers = [
    {
        name: "Alejandro José",
        role: "CEO & FOUNDER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Ale1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Ale2-scaled.jpg"
      },
      {
        name: "Mariana Rubens",
        role: "CORPORATE ASSOCIATE",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2022/02/Mariana-Rubens-1.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Marian2-scaled.jpg"
      },
      // Agrega más miembros según sea necesario
  ];

  const coordinatorMembers = [
    {
        name: "María Cecilia",
        role: "SEO MANAGER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2022/02/Cecilia-Furlan.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Ceci2.jpg"
      },
      {
        name: "María Paula",
        role: "COPYWRITING COORDINATOR",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2022/02/Maria-Paula-Desiderato-1.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/PauD2-scaled.jpg"
      },

      {
        name: "María Paula",
        role: "GRAPHIC DESIGNER COORDINATOR",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2022/02/Maria-Paula-Ciaccio.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Pauu2-scaled.jpg"
      },
      // Agrega más miembros según sea necesario
  ];

  const socials1Members = [
    {
        name: "Gianfranco",
        role: "ADS MANAGER COORDINATOR",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Gian1.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Gian2.jpg"
      },

      {
        name: "Camila",
        role: "SOCIAL MEDIA MANAGER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Cami1.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Cami2.jpg"
      },
      {
        name: "Mariana",
        role: "SOCIAL MEDIA MANAGER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Marian-S1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Marian-S2-scaled.jpg"
      },
      // Agrega más miembros según sea necesario
  ];
  
  const socials2Members = [

      {
        name: "Harold",
        role: "PRODUCT MANAGER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Harold-1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2022/09/fotos-todas-15-1.jpg "
      },
      {
        name: "Sofía",
        role: "COMMUNITY MANAGER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/SofiB1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/SofiB2-scaled.jpg"
      },
      {
        name: "Andrea",
        role: "GRAPHIC DESIGNER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/01/fotos-todas-05.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Andre2-scaled.jpg"
      },
      // Agrega más miembros según sea necesario
  ];

  const ManagersMembers = [
      {
        name: "Cristopher",
        role: "GRAPHIC DESIGNER",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Cris-1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Cris-2-scaled.jpg"
      },
      {
        name: "Santiago",
        role: "MAQUETADOR WORDPRESS",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Santi1.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/07/Santi2.jpg"
      },
      {
        name: "Ornella",
        role: "MAQUETADORA WORDPRESS",
        imageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Angie-1-scaled.jpg",
        hoverImageUrl: "https://mktmarketingdigital.com/wp-content/uploads/2023/10/Angie-2-scaled.jpg" 
      },
      // Agrega más miembros según sea necesario
  ];

  const GraphicMembers = [


      // Agrega más miembros según sea necesario
  ];

  if (!currentUser) {
    navigate("/login"); // Redireccionar si no hay un usuario actual
    return null;
  }

  return (
    <div className="equipo">
      <Sidebar />
      <div className="equipo-container">
        {/* Header con video de fondo */}
        <div className="team-header">
          <video autoPlay muted loop id="headerVideo">
            <source src="https://mktmarketingdigital.com/wp-content/uploads/2021/11/nosotros-somos.mp4" type="video/mp4" />
            Tu navegador no soporta videos.
          </video>
        </div>

        {/* Sección del título */}
        <h1 className="team-title">TEAM</h1>

 {/* Sección de CEO Members */}
 <div className="team-sections">
          {CEOMembers.map((member, index) => (
            <div key={index} className="CEO-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>

        {/* Sección de Coordinator Members */}
        <div className="team-sections">
          {coordinatorMembers.map((member, index) => (
            <div key={index} className="coordinator-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>

                {/* Sección de Socials1 Members */}
                <div className="team-sections">
          {socials1Members.map((member, index) => (
            <div key={index} className="socials1-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
    {/* Sección de Socials2 Members */}
    <div className="team-sections">
          {socials2Members.map((member, index) => (
            <div key={index} className="socials2-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>


                {/* Sección de Managers Members */}
                <div className="team-sections">
          {ManagersMembers.map((member, index) => (
            <div key={index} className="manager-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
    {/* Sección de Graphic Members */}
    <div className="team-sections">
          {GraphicMembers.map((member, index) => (
            <div key={index} className="graphic-member">
              <img 
                src={member.imageUrl} 
                alt={member.name} 
                onMouseEnter={e => e.currentTarget.src = member.hoverImageUrl}
                onMouseLeave={e => e.currentTarget.src = member.imageUrl}
              />
              <h5>{member.name}</h5>
              <p>{member.role}</p>
            </div>
          ))}
        </div>
            
      </div>
    </div>
  );
};

export default Equipo;
