import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';
import "./login.scss";
import googleLogo from '../../components/imagenes/googleLogo.png';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const getUserRoleByEmail = async (userEmail) => {
    const db = getFirestore();
    const rolesRef = collection(db, 'user_roles');
    const rolesQuery = query(rolesRef, where("email", "==", userEmail));
    const querySnapshot = await getDocs(rolesQuery);
    return querySnapshot.empty ? null : querySnapshot.docs[0].data().rol;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const result = await signInWithEmailAndPassword(auth, email, password);
      const userEmail = result.user.email;

      const userRole = await getUserRoleByEmail(userEmail);
      dispatch({ type: "LOGIN", payload: { user: result.user, role: userRole } });

      if (userRole === 'mkt') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'mde') {
        navigate("/mde/panelgeneral");
      } else if (userRole === 'misparrillas') {
        navigate("/misparrillas/panelgeneral");
      } else if (userRole === 'habitat') {
        navigate("/habitatgroup/panelgeneral");
      } else if (userRole === 'ckmiami') {
        navigate("/ckmiamicondos/panelgeneral");
      } else if (userRole === 'mariana') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'cami') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'jime') {
        navigate("/mkt/panelgeneral");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.error("Error al iniciar sesión:", err);
      setError("Usuario o contraseña incorrectos. Por favor, intente de nuevo.");
    }
  };

  const handleGoogleLogin = async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const userEmail = result.user.email;

      // Define userRole aquí antes de usarla
      const userRole = await getUserRoleByEmail(userEmail);

      // Ahora puedes usar userRole en tu dispatch
      dispatch({ type: "LOGIN", payload: { user: result.user, role: userRole } });

      // Lógica de redirección
      if (userRole === 'mkt') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'mde') {
        navigate("/mde/panelgeneral");
      } else if (userRole === 'misparrillas') {
        navigate("/misparrillas/panelgeneral");
      } else if (userRole === 'habitat') {
        navigate("/habitatgroup/panelgeneral");
      } else if (userRole === 'ckmiami') {
        navigate("/ckmiamicondos/panelgeneral");
      } else if (userRole === 'brokers') {
        navigate("/brokers/panelgeneral");
      } else if (userRole === 'claudiaserna') {
        navigate("/claudiaserna/panelgeneral");
      } else if (userRole === 'mariana') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'cami') {
        navigate("/mkt/panelgeneral");
      } else if (userRole === 'jime') {
        navigate("/mkt/panelgeneral");
      } else {
        navigate("/");
      }
    } catch (err) {
      console.error("Error al iniciar sesión con Google:", err);
      setError("Error al iniciar sesión con Google. Por favor, intente de nuevo.");
    }
  };

  return (
    <div className="login">
      {/* Login form and other JSX elements */}
      <div className="login-logo">
        <img src="https://mktmarketingdigital.com/wp-content/uploads/2021/11/cropped-cropped-firma-02@2x.png" alt="Login Logo" style={{ maxWidth: '100px', height: 'auto', display: 'block', margin: '0 auto 20px' }} />
      </div>
      <form onSubmit={handleLogin}>
        {/* Email input */}
        <div className="input-container">
          <FontAwesomeIcon icon={faEnvelope} className="fa-icon" />
          <input type="email" placeholder="Correo electrónico" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
        {/* Password input */}
        <div className="input-container">
          <FontAwesomeIcon icon={faLock} className="fa-icon" />
          <input type="password" placeholder="Contraseña" value={password} onChange={e => setPassword(e.target.value)} />
        </div>
        <button className="form-button" type="submit">Iniciar Sesión</button>
        {error && <span>Usuario o contraseña incorrectos</span>}
        </form>
        <div className="login-google">
          <button onClick={handleGoogleLogin} className="google-login-btn">
            <img src={googleLogo} alt="Google" />
            Iniciar sesión con Google
          </button>
        </div>
    </div>
  );
};

export default Login;