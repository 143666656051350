import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import ProtectedRoute from './ProtectedRoute';
import './fonts.css';

import Login from "./pages/login/Login";
import Equipo from "./pages/EquipoPage/Equipo";
import GoogleAnalyticsPanel from "./pages/GoogleAnalyticsPanel/GoogleAnalyticsPanel";
import GoogleAdsPanel from "./pages/GoogleAdsPanel/GoogleAdsPanel";
import MetaPanel from "./pages/MetaPanel/MetaPanel";
import SeoPanel from "./pages/SeoPanel/SeoPanel";
import ElegirCliente from "./pages/ElegirCliente/ElegirCliente";
import Proyectos from "./pages/Proyectos/Proyectos";
import PanelGeneral from "./pages/PanelGeneral/PanelGeneral";
import Perfil from './pages/Perfil/Perfil';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import SolicitudEnviada from './pages/SolicitudEnviada/SolicitudEnviada'; 


function App() {
  const { currentUser, role } = useContext(AuthContext);

  const redirectToRoleBasedRoute = () => {
    switch (role) {
      case 'mkt':
        return <Navigate to="/mkt/panelgeneral" />;
      case 'mariana':
        return <Navigate to="/mkt/panelgeneral" />;
      case 'cami':
        return <Navigate to="/mkt/panelgeneral" />;
        case 'natalia':
        return <Navigate to="/mkt/panelgeneral" />; 
      case 'mde':
        return <Navigate to="/mde/panelgeneral" />;
      case 'misparrillas':
        return <Navigate to="/misparrillas/panelgeneral" />;
      case 'habitat':
        return <Navigate to="/habitat/panelgeneral" />;
        case 'volveraescuchar':
        return <Navigate to="/volveraescuchar/panelgeneral" />;
        case 'beautyderm':
          return <Navigate to="/beautyderm/panelgeneral" />;
      case 'redpagos':
        return <Navigate to="/redpagoscavia/panelgeneral" />;     
      case 'dracignetti':
        return <Navigate to="/dracignetti/panelgeneral" />;
        case 'drjones':
        return <Navigate to="/drjones/panelgeneral" />;
      case 'benozzi':
        return <Navigate to="/benozzi/panelgeneral" />;  
      case 'bao':
        return <Navigate to="/bao/panelgeneral" />;     
      case 'puntaballena':
        return <Navigate to="/puntaballena/panelgeneral" />;
        case 'tgc':
          return <Navigate to="/tgc/panelgeneral" />;  
          case 'moscow':
            return <Navigate to="/moscow/panelgeneral" />;
      // Agrega aquí más casos según sea necesario
      default:
        return <Navigate to="/SolicitudEnviada" />; // Redirigir al login si no hay rol definido
    }
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Redirige desde la ruta raíz basándose en el rol del usuario */}
        <Route 
          path="/" 
          element={currentUser ? redirectToRoleBasedRoute() : <Navigate to="/login" />} 
        />
  

        <Route path="login" element={<Login />} />

        <Route path="/SolicitudEnviada" element={<SolicitudEnviada />} />

        <Route path="/elegircliente" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <ElegirCliente />
          </ProtectedRoute>
        } />
                <Route path="/proyectos" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Proyectos />
          </ProtectedRoute>
        } />

        <Route path="/mkt/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <PanelGeneral cliente="mkt" />
          </ProtectedRoute>
        } />

        <Route path="/mkt/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <Equipo cliente="mkt" />
          </ProtectedRoute>
        } />
        
        <Route path="/mkt/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <GoogleAnalyticsPanel cliente="mkt" />
          </ProtectedRoute>
        } />

        <Route path="/mkt/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <GoogleAdsPanel cliente="mkt" />
          </ProtectedRoute>
        } />
 
        <Route path="/mkt/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <MetaPanel cliente="mkt" />
          </ProtectedRoute>
        } />

        <Route path="/mkt/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <SeoPanel cliente="mkt" />
          </ProtectedRoute>
        } />

        <Route path="/mkt/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <Perfil cliente="mkt" />
          </ProtectedRoute>
        } />

        <Route path="/mde/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <PanelGeneral cliente="mde" />
          </ProtectedRoute>
        } />

        <Route path="/mde/equipo" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <Equipo cliente="mde" />
          </ProtectedRoute>
        } />
        
        <Route path="/mde/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <GoogleAnalyticsPanel cliente="mde" />
          </ProtectedRoute>
        } />

        <Route path="/mde/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'agustin', 'harold']}>
            <GoogleAdsPanel cliente="mde" />
          </ProtectedRoute>
        } />
 
        <Route path="/mde/metapanel" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <MetaPanel cliente="mde" />
          </ProtectedRoute>
        } />

        <Route path="/mde/seopanel" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <SeoPanel cliente="mde" />
          </ProtectedRoute>
        } />

        <Route path="/mde/perfil" element={
          <ProtectedRoute allowedRoles={['mde', 'mkt', 'mariana', 'cami', 'natalia', 'harold']}>
            <Perfil cliente="mde" />
          </ProtectedRoute>
        } />
         <Route path="/misparrillas/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <PanelGeneral cliente="misparrillas" />
          </ProtectedRoute>
        } />

        <Route path="/misparrillas/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <Equipo cliente="misparrillas" />
          </ProtectedRoute>
        } />
        
        <Route path="/misparrillas/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia','misparrillas']}>
            <GoogleAnalyticsPanel cliente="misparrillas" />
          </ProtectedRoute>
        } />

        <Route path="/misparrillas/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <GoogleAdsPanel cliente="misparrillas" />
          </ProtectedRoute>
        } />
 
        <Route path="/misparrillas/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <MetaPanel cliente="misparrillas" />
          </ProtectedRoute>
        } />

        <Route path="/misparrillas/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <SeoPanel cliente="misparrillas" />
          </ProtectedRoute>
        } />

        <Route path="/misparrillas/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'misparrillas']}>
            <Perfil cliente="misparrillas" />
          </ProtectedRoute>
        } />
         <Route path="/redpagoscavia/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos' ]}>
            <PanelGeneral cliente="redpagos" />
          </ProtectedRoute>
        } />

        <Route path="/redpagoscavia/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <Equipo cliente="redpagos" />
          </ProtectedRoute>
        } />
        
        <Route path="/redpagoscavia/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <GoogleAnalyticsPanel cliente="redpagos" />
          </ProtectedRoute>
        } />

        <Route path="/redpagoscavia/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <GoogleAdsPanel cliente="redpagos" />
          </ProtectedRoute>
        } />
 
        <Route path="/redpagoscavia/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <MetaPanel cliente="redpagos" />
          </ProtectedRoute>
        } />

        <Route path="/redpagoscavia/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <SeoPanel cliente="redpagos" />
          </ProtectedRoute>
        } />

        <Route path="/redpagoscavia/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'redpagos']}>
            <Perfil cliente="redpagos" />
          </ProtectedRoute>
        } />
        <Route path="/habitatgroup/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <PanelGeneral cliente="habitatgroup" />
          </ProtectedRoute>
        } />

        <Route path="/habitatgroup/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Equipo cliente="habitatgroup" />
          </ProtectedRoute>
        } />
        
        <Route path="/habitatgroup/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAnalyticsPanel cliente="habitatgroup" />
          </ProtectedRoute>
        } />

        <Route path="/habitatgroup/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAdsPanel cliente="habitatgroup" />
          </ProtectedRoute>
        } />
 
        <Route path="/habitatgroup/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <MetaPanel cliente="habitatgroup" />
          </ProtectedRoute>
        } />

        <Route path="/habitatgroup/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <SeoPanel cliente="habitatgroup" />
          </ProtectedRoute>
        } />

        <Route path="/habitatgroup/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Perfil cliente="habitatgroup" />
          </ProtectedRoute>
        } />
                <Route path="/EB-5/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <PanelGeneral cliente="EB-5" />
          </ProtectedRoute>
        } />

        <Route path="/EB-5/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Equipo cliente="EB-5" />
          </ProtectedRoute>
        } />
        
        <Route path="/EB-5/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAnalyticsPanel cliente="EB-5" />
          </ProtectedRoute>
        } />

        <Route path="/EB-5/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAdsPanel cliente="EB-5" />
          </ProtectedRoute>
        } />
 
        <Route path="/EB-5/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <MetaPanel cliente="EB-5" />
          </ProtectedRoute>
        } />

        <Route path="/EB-5/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <SeoPanel cliente="EB-5" />
          </ProtectedRoute>
        } />

        <Route path="/EB-5/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Perfil cliente="EB-5" />
          </ProtectedRoute>
        } />
          <Route path="/smartbrickell/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <PanelGeneral cliente="smartbrickell" />
          </ProtectedRoute>
        } />

           <Route path="/smartbrickell/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Equipo cliente="smartbrickell" />
          </ProtectedRoute>
        } />
        
        <Route path="/smartbrickell/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAnalyticsPanel cliente="smartbrickell" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickell/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAdsPanel cliente="smartbrickell" />
          </ProtectedRoute>
        } />
 
        <Route path="/smartbrickell/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <MetaPanel cliente="smartbrickell" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickell/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <SeoPanel cliente="smartbrickell" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickell/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Perfil cliente="smartbrickell" />
          </ProtectedRoute>
        } />

          <Route path="/smartbrickellhotel/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <PanelGeneral cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />

           <Route path="/smartbrickellhotel/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Equipo cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />
        
        <Route path="/smartbrickellhotel/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAnalyticsPanel cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickellhotel/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <GoogleAdsPanel cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />
 
        <Route path="/smartbrickellhotel/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <MetaPanel cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickellhotel/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <SeoPanel cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />

        <Route path="/smartbrickellhotel/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'habitat']}>
            <Perfil cliente="smartbrickellhotel" />
          </ProtectedRoute>
        } />

        <Route path="/ckmiamicondos/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <PanelGeneral cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />

        <Route path="/ckmiamicondos/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <Equipo cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />
        
        <Route path="/ckmiamicondos/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <GoogleAnalyticsPanel cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />

        <Route path="/ckmiamicondos/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <GoogleAdsPanel cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />
 
        <Route path="/ckmiamicondos/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <MetaPanel cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />

        <Route path="/ckmiamicondos/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <SeoPanel cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />

        <Route path="/ckmiamicondos/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <Perfil cliente="ckmiamicondos" />
          </ProtectedRoute>
        } />

       <Route path="/brokers/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <PanelGeneral cliente="brokers" />
          </ProtectedRoute>
        } />

        <Route path="/brokers/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <Equipo cliente="brokers" />
          </ProtectedRoute>
        } />
        
        <Route path="/brokers/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <GoogleAnalyticsPanel cliente="brokers" />
          </ProtectedRoute>
        } />

        <Route path="/brokers/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <GoogleAdsPanel cliente="brokers" />
          </ProtectedRoute>
        } />
 
        <Route path="/brokers/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <MetaPanel cliente="brokers" />
          </ProtectedRoute>
        } />

        <Route path="/brokers/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <SeoPanel cliente="brokers" />
          </ProtectedRoute>
        } />

        <Route path="/brokers/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'brokers']}>
            <Perfil cliente="brokers" />
          </ProtectedRoute>
        } />
       <Route path="/claudiaserna/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <PanelGeneral cliente="claudiaserna" />
          </ProtectedRoute>
        } />

        <Route path="/claudiaserna/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <Equipo cliente="claudiaserna" />
          </ProtectedRoute>
        } />
        
        <Route path="/claudiaserna/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <GoogleAnalyticsPanel cliente="claudiaserna" />
          </ProtectedRoute>
        } />

        <Route path="/claudiaserna/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <GoogleAdsPanel cliente="claudiaserna" />
          </ProtectedRoute>
        } />
 
        <Route path="/claudiaserna/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <MetaPanel cliente="claudiaserna" />
          </ProtectedRoute>
        } />

        <Route path="/claudiaserna/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'ckmiami']}>
            <SeoPanel cliente="claudiaserna" />
          </ProtectedRoute>
        } />

        <Route path="/claudiaserna/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'claudiaserna']}>
            <Perfil cliente="claudiaserna" />
          </ProtectedRoute>
        } />
       <Route path="/volveraescuchar/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <PanelGeneral cliente="volveraescuchar" />
          </ProtectedRoute>
        } />

        <Route path="/volveraescuchar/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <Equipo cliente="volveraescuchar" />
          </ProtectedRoute>
        } />
        
        <Route path="/volveraescuchar/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <GoogleAnalyticsPanel cliente="volveraescuchar" />
          </ProtectedRoute>
        } />

        <Route path="/volveraescuchar/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <GoogleAdsPanel cliente="volveraescuchar" />
          </ProtectedRoute>
        } />
 
        <Route path="/volveraescuchar/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <MetaPanel cliente="volveraescuchar" />
          </ProtectedRoute>
        } />

        <Route path="/volveraescuchar/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <SeoPanel cliente="volveraescuchar" />
          </ProtectedRoute>
        } />

        <Route path="/volveraescuchar/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'volveraescuchar']}>
            <Perfil cliente="volveraescuchar" />
          </ProtectedRoute>
        } />
       <Route path="/cursomktinmobiliario/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <PanelGeneral cliente="cursomktinmobiliario" />
          </ProtectedRoute> 
        } />

        <Route path="/cursomktinmobiliario/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <Equipo cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />
        
        <Route path="/cursomktinmobiliario/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <GoogleAnalyticsPanel cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />

        <Route path="/cursomktinmobiliario/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <GoogleAdsPanel cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />
 
        <Route path="/cursomktinmobiliario/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <MetaPanel cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />

        <Route path="/cursomktinmobiliario/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <SeoPanel cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />

        <Route path="/cursomktinmobiliario/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'cmi', 'harold']}>
            <Perfil cliente="cursomktinmobiliario" />
          </ProtectedRoute>
        } />
       <Route path="/baoworkcenter/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <PanelGeneral cliente="baoworkcenter" />
          </ProtectedRoute>
        } />

        <Route path="/baoworkcenter/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <Equipo cliente="baoworkcenter" />
          </ProtectedRoute>
        } />
        
        <Route path="/baoworkcenter/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <GoogleAnalyticsPanel cliente="baoworkcenter" />
          </ProtectedRoute>
        } />

        <Route path="/baoworkcenter/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <GoogleAdsPanel cliente="baoworkcenter" />
          </ProtectedRoute>
        } />
 
        <Route path="/baoworkcenter/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <MetaPanel cliente="baoworkcenter" />
          </ProtectedRoute>
        } />

        <Route path="/baoworkcenter/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <SeoPanel cliente="baoworkcenter" />
          </ProtectedRoute>
        } />

        <Route path="/baoworkcenter/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'bao']}>
            <Perfil cliente="baoworkcenter" />
          </ProtectedRoute>
        } />
       <Route path="/drjones/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <PanelGeneral cliente="drjones" />
          </ProtectedRoute>
        } />

        <Route path="/drjones/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <Equipo cliente="drjones" />
          </ProtectedRoute>
        } />
        
        <Route path="/drjones/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <GoogleAnalyticsPanel cliente="drjones" />
          </ProtectedRoute>
        } />

        <Route path="/drjones/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <GoogleAdsPanel cliente="drjones" />
          </ProtectedRoute>
        } />
 
        <Route path="/drjones/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <MetaPanel cliente="drjones" />
          </ProtectedRoute>
        } />

        <Route path="/drjones/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <SeoPanel cliente="drjones" />
          </ProtectedRoute>
        } />

        <Route path="/drjones/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'drjones']}>
            <Perfil cliente="drjones" />
          </ProtectedRoute>
        } />

        <Route path="/puntaballena/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <PanelGeneral cliente="puntaballena" />
          </ProtectedRoute>
        } />

        <Route path="/puntaballena/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <Equipo cliente="puntaballena" />
          </ProtectedRoute>
        } />
        
        <Route path="/puntaballena/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <GoogleAnalyticsPanel cliente="puntaballena" />
          </ProtectedRoute>
        } />

        <Route path="/puntaballena/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <GoogleAdsPanel cliente="puntaballena" />
          </ProtectedRoute>
        } />
 
        <Route path="/puntaballena/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <MetaPanel cliente="puntaballena" />
          </ProtectedRoute>
        } />

        <Route path="/puntaballena/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <SeoPanel cliente="puntaballena" />
          </ProtectedRoute>
        } />

        <Route path="/puntaballena/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'puntaballena']}>
            <Perfil cliente="puntaballena" />
          </ProtectedRoute>
        } />
        <Route path="/benozzi/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <PanelGeneral cliente="benozzi" />
          </ProtectedRoute>
        } />

        <Route path="/benozzi/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <Equipo cliente="benozzi" />
          </ProtectedRoute>
        } />
        
        <Route path="/benozzi/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <GoogleAnalyticsPanel cliente="benozzi" />
          </ProtectedRoute>
        } />

        <Route path="/benozzi/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <GoogleAdsPanel cliente="benozzi" />
          </ProtectedRoute>
        } />
 
        <Route path="/benozzi/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <MetaPanel cliente="benozzi" />
          </ProtectedRoute>
        } />

        <Route path="/benozzi/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <SeoPanel cliente="benozzi" />
          </ProtectedRoute>
        } />

        <Route path="/benozzi/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'benozzi']}>
            <Perfil cliente="benozzi" />
          </ProtectedRoute>
        } />
        <Route path="/tgc/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <PanelGeneral cliente="tgc" />
          </ProtectedRoute>
        } />

        <Route path="/tgc/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <Equipo cliente="tgc" />
          </ProtectedRoute>
        } />
        
        <Route path="/tgc/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <GoogleAnalyticsPanel cliente="tgc" />
          </ProtectedRoute>
        } />

        <Route path="/tgc/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <GoogleAdsPanel cliente="tgc" />
          </ProtectedRoute>
        } />
 
        <Route path="/tgc/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <MetaPanel cliente="tgc" />
          </ProtectedRoute>
        } />

        <Route path="/tgc/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <SeoPanel cliente="tgc" />
          </ProtectedRoute>
        } />

        <Route path="/tgc/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'tgc']}>
            <Perfil cliente="tgc" />
          </ProtectedRoute>
        } />
                <Route path="/dracignetti/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <PanelGeneral cliente="dracignetti" />
          </ProtectedRoute>
        } />

        <Route path="/dracignetti/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <Equipo cliente="dracignetti" />
          </ProtectedRoute>
        } />
        
        <Route path="/dracignetti/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <GoogleAnalyticsPanel cliente="dracignetti" />
          </ProtectedRoute>
        } />

        <Route path="/dracignetti/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <GoogleAdsPanel cliente="dracignetti" />
          </ProtectedRoute>
        } />
 
        <Route path="/dracignetti/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <MetaPanel cliente="dracignetti" />
          </ProtectedRoute>
        } />

        <Route path="/dracignetti/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <SeoPanel cliente="dracignetti" />
          </ProtectedRoute>
        } />

        <Route path="/dracignetti/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'dracignetti']}>
            <Perfil cliente="dracignetti" />
          </ProtectedRoute>
        } />
                        <Route path="/beautyderm/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <PanelGeneral cliente="beautyderm" />
          </ProtectedRoute>
        } />

        <Route path="/beautyderm/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <Equipo cliente="beautyderm" />
          </ProtectedRoute>
        } />
        
        <Route path="/beautyderm/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <GoogleAnalyticsPanel cliente="beautyderm" />
          </ProtectedRoute>
        } />

        <Route path="/beautyderm/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <GoogleAdsPanel cliente="beautyderm" />
          </ProtectedRoute>
        } />
 
        <Route path="/beautyderm/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <MetaPanel cliente="beautyderm" />
          </ProtectedRoute>
        } />

        <Route path="/beautyderm/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <SeoPanel cliente="beautyderm" />
          </ProtectedRoute>
        } />

        <Route path="/beautyderm/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'beautyderm']}>
            <Perfil cliente="beautyderm" />
          </ProtectedRoute>
        } />
                                <Route path="/moscow/panelgeneral" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <PanelGeneral cliente="moscow" />
          </ProtectedRoute>
        } />

        <Route path="/moscow/equipo" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <Equipo cliente="moscow" />
          </ProtectedRoute>
        } />
        
        <Route path="/moscow/googleanalyticspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <GoogleAnalyticsPanel cliente="moscow" />
          </ProtectedRoute>
        } />

        <Route path="/moscow/googleadspanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <GoogleAdsPanel cliente="moscow" />
          </ProtectedRoute>
        } />
 
        <Route path="/moscow/metapanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <MetaPanel cliente="moscow" />
          </ProtectedRoute>
        } />

        <Route path="/moscow/seopanel" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <SeoPanel cliente="moscow" />
          </ProtectedRoute>
        } />

        <Route path="/moscow/perfil" element={
          <ProtectedRoute allowedRoles={['mkt', 'mariana', 'cami', 'natalia', 'moscow']}>
            <Perfil cliente="moscow" />
          </ProtectedRoute>
        } />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="*" element={<Navigate to="/not-found" />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
