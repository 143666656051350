import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate desde react-router-dom
import { AuthContext } from "../../context/AuthContext"; // Asegúrate de que esta ruta sea la correcta
import './GoogleAdsPanel.scss';
import Sidebar from "../../components/sidebar/Sidebar";

const GoogleAdsPanel = ({ cliente }) => {
  let lookerEmbedUrl;

  if (cliente === "mkt") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ad012529-5cd7-4219-b820-623464b6993b/page/B2HlD"; 
  } else if (cliente === "mde") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/dc7969b6-2695-4128-9a07-454737df66a8/page/B2HlD"; // URL del informe de Looker para el cliente "mde"
  } else if (cliente === "misparrillas") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/29a04054-1d64-4ea8-8542-5d3806f8d9f7/page/IqNnD";
  } else if (cliente === "ckmiamicondos") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ed967bb6-0d7f-44f9-acd6-0f828897af45/page/B2HlD";    
  } else if (cliente === "brokers") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/e6fa6df5-62bc-471e-aa14-a8a372592ecb/page/B2HlD";
  } else if (cliente === "claudiaserna") {
    lookerEmbedUrl = " https://lookerstudio.google.com/embed/reporting/255cd587-7ad3-4036-8bed-344c061fa75b/page/B2HlD";
  } else if (cliente === "habitatgroup") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/b5d4bfd5-49d3-4a66-aa70-396902611b6c/page/B2HlD";
  } else if (cliente === "EB-5") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/1ebf231c-63dc-434d-a40c-995ee43ab591/page/B2HlD";
  } else if (cliente === "smartbrickell") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/859df9b6-9972-4b8f-abf6-35147f2cbec5/page/B2HlD";
  } else if (cliente === "smartbrickellhotel") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/961f809c-7489-4a50-8e2a-6a847bf83a3b/page/B2HlD";
  } else if (cliente === "volveraescuchar") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/af443637-ff6f-46c3-8baf-23cb8c1d3ff6/page/B2HlD";
  } else if (cliente === "baoworkcenter") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/517fedbb-fcdc-49ca-a0b2-9c816b861b72/page/IqNnD";
  } else if (cliente === "redpagos") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/f8dd3094-6ef0-4fba-8ee0-3d1d5bdb3d9d/page/B2HlD";
  } else if (cliente === "tgc") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/a8861937-7192-49eb-9055-cfc87dabdc5f/page/B2HlD";
  } else if (cliente === "puntaballena") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/b47d0357-c83f-4573-aef4-0d205fcb025b/page/IqNnD";
  } else if (cliente === "benozzi") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/051263e8-47f1-4870-ac01-38880cc1d2f6/page/IqNnD";
  } else if (cliente === "dracignetti") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/b213ea93-550a-4934-8b93-ce24a7132609/page/B2HlD";
  } else if (cliente === "beautyderm") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/93d02e4a-b922-418a-b10d-e57f1646b54c/page/B2HlD";
  } else {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/notfound"
  }

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    navigate("/login"); // Asegúrate de que la ruta "/login" es correcta para tu aplicación
    return null;
  }
  return (
    <div className="ads-panel">
      <Sidebar />
      <div className="ads-container">
        <div>
          <div className="iframe-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe 
              width="1650" 
              height="1100" 
              src={lookerEmbedUrl}
              frameBorder="0" 
              style={{ border: '0' }} 
              allowFullScreen 
              title="Panel de Looker Studio"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdsPanel;

