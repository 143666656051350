import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";
import './GoogleAnalyticsPanel.scss';
import Sidebar from "../../components/sidebar/Sidebar";

const GoogleAnalyticsPanel = ({ cliente }) => {
  let lookerEmbedUrl;

  if (cliente === "mkt") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/7fe3a412-6a5c-4868-b0c0-8a33f8324c1f/page/taAlD";
  } else if (cliente === "mde") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/a590b4aa-b686-40f4-8b01-94c915c1d421/page/taAlD";
  } else if (cliente === "misparrillas") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/19bbdf30-8c8b-4b89-886c-79d07f53f345/page/kfMnD";
  } else if (cliente === "habitatgroup") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/e6ea3e39-f073-4f3a-acef-4ad34e1f2cd4/page/taAlD";
  } else if (cliente === "EB-5") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/9870b02a-c981-4f17-8a23-bd18680cf885/page/taAlD";
  } else if (cliente === "smartbrickell") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/69b615e2-cdf0-4e6b-a1ba-e86b114476ff/page/taAlD";
  } else if (cliente === "smartbrickellhotel") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/75f95b3f-d0ec-462d-b1c3-17cc0aac6816/page/taAlD";
  } else if (cliente === "volveraescuchar") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/05fe1a6a-42ba-4d2b-b318-361b80fe93f8/page/taAlD";
  } else if (cliente === "baoworkcenter") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/66e6ba65-ed24-409c-ac0f-efe62613e117/page/kfMnD";
  } else if (cliente === "redpagos") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/9958cb25-bd20-43ef-81e4-d92e32df86ac/page/kfMnD";
  } else if (cliente === "tgc") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/a13d0612-2b23-4b00-b5c1-840c420031c7/page/kfMnD";
  } else if (cliente === "puntaballena") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/42d50e25-4ab9-4506-a742-b78c643eef73/page/kfMnD";
  } else if (cliente === "benozzi") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/f287f49f-3343-43d8-84c6-de3d72444a7b/page/kfMnD";
  } else if (cliente === "dracignetti") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/120f86f4-6085-4959-9c77-68572acd6e4d/page/taAlD";
  } else if (cliente === "beautyderm") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/1c0c103f-ddb3-416b-9ee6-65bd36bcaa8e/page/taAlD";
  }
  

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    navigate("/login");
    return null;
  }

  return (
    <div className="analytics-panel">
      <Sidebar />
      <div className="analytics-container">
        <div className="iframe-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <iframe
            width="1650"
            height="1100"
            src={lookerEmbedUrl}
            frameBorder="0"
            style={{ border: '0' }}
            title="Panel de Looker Studio"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GoogleAnalyticsPanel;



