import React from 'react';
import './WelcomePopup.scss'; // Asegúrate de importar los estilos aquí

const WelcomePopup = ({ message }) => {
  return (
    <div className="welcome-popup">
      {message}
    </div>
  );
};

export default WelcomePopup;