import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { currentUser, role } = useContext(AuthContext);

  if (!currentUser) {
    // Redirige al login si no hay usuario
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(role)) {
    // Redirige a una ruta de acceso denegado si el rol no está permitido
    return <Navigate to="/not-found" />;
  }

  // Renderiza los componentes hijos si el usuario está autenticado y tiene un rol permitido
  return children;
};



export default ProtectedRoute;

