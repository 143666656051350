import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Proyectos.scss';

const Proyectos = () => {
  const navigate = useNavigate();

  const handleClientSelection = (clientName) => {
    switch (clientName) {
      case "Habitat Group":
        navigate('/habitatgroup/panelgeneral');
        break;
        case "Smart Brickell":
        navigate('/smartbrickell/panelgeneral');
         break;
         case "Smart Brickell Hotel":
            navigate('/smartbrickellhotel/panelgeneral');
             break;
         case "EB-5":
         navigate('/EB-5/panelgeneral');
           break;        
      // Agrega más casos para otros clientes si es necesario
      default:
        // Manejar casos no especificados si es necesario
        break;
    }
  };

  return (
    <div className="proyectos-container">
      <h1>Seleccione un proyecto</h1>
      <div className="clientes-grid">
        <button onClick={() => handleClientSelection("Habitat Group")}>
         Habitat Group
        </button>
        <button onClick={() => handleClientSelection("EB-5")}>
         Landing EB-5
        </button>
        <button onClick={() => handleClientSelection("Smart Brickell")}>
         Smart Brickell
        </button>
        <button onClick={() => handleClientSelection("Smart Brickell Hotel")}>
         Smart Brickell Hotel
        </button>
        {/* Añade más botones para otros clientes aquí si es necesario */}
      </div>
    </div>
  );
};

export default Proyectos;

