import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate desde react-router-dom
import { AuthContext } from "../../context/AuthContext"; // Asegúrate de que esta ruta sea la correcta
import './MetaPanel.scss';
import Sidebar from "../../components/sidebar/Sidebar";

const MetaPanel = ({ cliente }) => {
  let lookerEmbedUrl;

  if (cliente === "mkt") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/a5d46ae7-1bb6-4693-907f-c972b2bc7384/page/p_zfwbyzuaed"; 
  } else if (cliente === "mde") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/90129603-e5f6-41c1-babf-169898746732/page/p_wcv7dpvscd"; // URL del informe de Looker para el cliente "mde"
  } else if (cliente === "misparrillas") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/60aaed4c-472d-46a7-bd87-d37556703ba8/page/czBoD";      
  } else if (cliente === "habitatgroup") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/6db41824-f6cc-4118-8b6f-7ab34ab9c3dd/page/p_wcv7dpvscd";
  } else if (cliente === "EB-5") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/7477affe-305c-4868-96e4-d531cf15a17c/page/p_zfwbyzuaed";
  } else if (cliente === "baoworkcenter") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ab2a300d-beb2-4521-80b7-a1a700a2d749/page/czBoD";
  } else if (cliente === "redpagos") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ddae05d0-e525-47f9-a6d6-b513f516f909/page/p_3limu6nudd";
  } else if (cliente === "tgc") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/66f68bf4-e0d5-48db-9ebe-16bfd9c8168e/page/p_3limu6nudd";
  } else if (cliente === "puntaballena") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/71171b07-ca86-4bb8-875c-791b430733f3/page/czBoD";
  } else if (cliente === "benozzi") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/0f92ccb8-baaf-4e16-afe1-1d88f13f5fb1/page/czBoD";
  } else if (cliente === "dracignetti") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/7de02309-a5c2-4ad9-811b-5f5f60845075/page/p_wcv7dpvscd";
  } else if (cliente === "volveraescuchar") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/96ca40eb-25a7-4f7b-a819-f9545b105121/page/p_v65f94nudd";
  } else if (cliente === "beautyderm") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/4f55842b-5935-4f22-9c0a-42a9974c31f6/page/p_zfwbyzuaed";
  } else {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/notfound"; 
  }
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    navigate("/login"); // Asegúrate de que la ruta "/login" es correcta para tu aplicación
    return null;
  }
  return (
    <div className="meta-panel">
      <Sidebar />
      <div className="meta-container">
        <div>
          <div className="iframe-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe 
              width="1650" 
              height="1100" 
              src={lookerEmbedUrl}
              frameBorder="0" 
              style={{ border: '0' }} 
              allowFullScreen 
              title="Panel de Looker Studio"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetaPanel;

