import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate desde react-router-dom
import { AuthContext } from "../../context/AuthContext"; // Asegúrate de que esta ruta sea la correcta
import './SeoPanel.scss';
import Sidebar from "../../components/sidebar/Sidebar";

const SeoPanel = ({ cliente }) => {
  let lookerEmbedUrl;

  if (cliente === "mkt") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/90477351-b9f5-47c2-a815-49162007a134/page/beCmD"; 
  } else if (cliente === "mde") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/03611443-2016-4fe8-aa0e-25db54e575c8/page/beCmD"; // URL del informe de Looker para el cliente "mde"
  } else if (cliente === "misparrillas") {
      lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/2ad8ba87-fcf0-4389-b143-b0d5bfa852f9/page/1JIoD";
  } else if (cliente === "volveraescuchar") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ee985ef8-15a5-4330-97da-da462fe2a34a/page/beCmD";      
  } else if (cliente === "redpagos") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/2ccbc05b-835d-40ee-9c8c-27d63c95a4ba/page/beCmD";
  } else if (cliente === "puntaballena") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/c4012da5-3765-481a-a167-2c58a8555fd9/page/beCmDD";
  } else if (cliente === "benozzi") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/0a3a75ca-baa8-4b21-991e-419e0d89710f/page/beCmD";
  } else if (cliente === "habitatgroup") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/cd34b480-d367-4dc3-990a-40b6ae2053eb/page/beCmD";
  } else if (cliente === "EB-5") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/c1616c45-cef5-416c-95fd-e5cb98491438/page/beCmD";
  } else if (cliente === "smartbrickell") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/ba9b2511-7c8a-4100-816e-14bb71c2ffb7/page/beCmD";
  } else if (cliente === "smartbrickellhotel") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/37433205-59cf-4b03-bcff-df2019e8e86a/page/beCmD";
  } else if (cliente === "baoworkcenter") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/28e56955-73f3-44e2-95aa-4ee458c5fa34/page/beCmD";
  } else if (cliente === "tgc") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/7388ef45-0894-486a-b626-1c380a861998/page/1JIoD";
  } else if (cliente === "dracignetti") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/9c655b05-7cb5-4cbd-8a2b-b7fbd8c09f7c/page/beCmD";
  } else if (cliente === "beautyderm") {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/2b30f74d-b843-42f8-b465-e68e0ea615ec/page/beCmD";
  } else {
    lookerEmbedUrl = "https://lookerstudio.google.com/embed/reporting/notfound"
  }
  
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    navigate("/login"); // Asegúrate de que la ruta "/login" es correcta para tu aplicación
    return null;
  }
  return (
    <div className="seo-panel">
      <Sidebar />
      <div className="seo-container">
        <div>
          <div className="iframe-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <iframe 
              width="1650" 
              height="1100" 
              src={lookerEmbedUrl}
              frameBorder="0" 
              style={{ border: '0' }} 
              allowFullScreen 
              title="Panel de Looker Studio"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeoPanel;

