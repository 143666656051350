import React, { useState, useEffect, useContext } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import './sidebar.scss';
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import InsightsIcon from '@mui/icons-material/Insights';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PersonSearchSharpIcon from '@mui/icons-material/PersonSearchSharp';
import { logout } from '../../firebase.js';
import { AuthContext } from ".//../../context/AuthContext.js";
import logoMkt from '../../components/imagenes/logoMkt.png';
// import logoMde from '../../components/imagenes/logoMde.png';
// import logoHabitat from '../../components/imagenes/logoHabitat.png';
// import logoRedpagos from '../../components/imagenes/logoRedpagos.png';
// import logoMisparrillas from '../../components/imagenes/logoMisParrillas.png';
// import logoCkmiami from '../../components/imagenes/logoCkmiami.png';
// import logoBrokers from '../../components/imagenes/logoBrokers.svg';

const Sidebar = ({ cliente }) => {
  const { role, dispatch } = useContext(AuthContext);
  const [activeItem, setActiveItem] = useState('');
  const location = useLocation();
  const [basePath, setBasePath] = useState('');
  const allowedRolesForClientesSection = ["mkt", "cami", "natalia", "mariana"];
  const allowedRolesForHabitatSection = ["mkt", "cami", "natalia", "mariana", "habitat"];
  const allowedRolesForDownload = ["mkt", "mariana", "natalia", "cami"];

  const [links, setLinks] = useState({
    googleAnalytics: '',
    googleAds: '',
    metaPanel: '',
    seoPanel: '',
    equipo: '',
    Perfil: '',
    PanelGeneral: ''
  });

  const handleLogout = () => {
    logout()
      .then(() => {
        dispatch({ type: 'LOGOUT' });
      })
      .catch((error) => {
        console.error("Error al cerrar sesión: ", error);
      });
  };

  useEffect(() => {
    let newBasePath = '';
    if (location.pathname.includes('/mkt/')) {
      newBasePath = '/mkt';
    } else if (location.pathname.includes('/mde/')) {
      newBasePath = '/mde';
    } else if (location.pathname.includes('/cursomktinmobiliario/')) {
      newBasePath = '/cursomktinmobiliario';
    } else if (location.pathname.includes('/misparrillas/')) {
      newBasePath = '/misparrillas';
    } else if (location.pathname.includes('/habitatgroup/')) {
      newBasePath = '/habitatgroup';
    } else if (location.pathname.includes('/EB-5/')) {
      newBasePath = '/EB-5';
    } else if (location.pathname.includes('/smartbrickell/')) {
      newBasePath = '/smartbrickell';
    } else if (location.pathname.includes('/smartbrickellhotel/')) {
      newBasePath = '/smartbrickellhotel';
    } else if (location.pathname.includes('/redpagoscavia/')) {
      newBasePath = '/redpagoscavia';
    } else if (location.pathname.includes('/volveraescuchar/')) {
      newBasePath = '/volveraescuchar';
    } else if (location.pathname.includes('/baoworkcenter/')) {
      newBasePath = '/baoworkcenter';
    } else if (location.pathname.includes('/drjones/')) {
      newBasePath = '/drjones';
    } else if (location.pathname.includes('/benozzi/')) {
      newBasePath = '/benozzi';
    } else if (location.pathname.includes('/puntaballena/')) {
      newBasePath = '/puntaballena';
    } else if (location.pathname.includes('/tgc/')) {
      newBasePath = '/tgc';
    } else if (location.pathname.includes('/dracignetti/')) {
      newBasePath = '/dracignetti';
    } else if (location.pathname.includes('/beautyderm/')) {
      newBasePath = '/beautyderm';
    } else if (location.pathname.includes('/moscow/')) {
      newBasePath = '/moscow';
    } else if (location.pathname.includes('/xyz/')) {
      newBasePath = '/xyz';
    } else {
      newBasePath = ''; // O alguna ruta base por defecto si es necesario
    }

    setBasePath(newBasePath);

    setLinks({
      googleAnalytics: `${newBasePath}/googleanalyticspanel`,
      googleAds: `${newBasePath}/googleadspanel`,
      metaPanel: `${newBasePath}/metapanel`,
      seoPanel: `${newBasePath}/seopanel`,
      equipo: `${newBasePath}/equipo`,
      Perfil: `${newBasePath}/perfil`,
      PanelGeneral: `${newBasePath}/panelgeneral`
    });

    setActiveItem(location.pathname);
  }, [location]);

  let logoUrl;
  let linkUrl;
  switch (basePath) {
    //case '/mkt':
      //logoUrl = logoMkt;
      //linkUrl = "https://mktmarketingdigital.com";
      //break;
    //case '/mde':
      //logoUrl = logoMde;
      //linkUrl = "https://marketingdigitalexperience.com";
      //break;
    //case '/misparrillas':
      //logoUrl = logoMisparrillas;
      //linkUrl = "https://www.misparrillas.com.ar/";
      //break;
      //case '/habitatgroup':
        //logoUrl = logoHabitat;
        //linkUrl = "https://www.habitatgroup.com/";
        //break;
      //case '/ckmiamicondos':
        //logoUrl = logoCkmiami;
        //linkUrl = "https://ckmiamicondos.vip/";
        //break;
      //case '/brokers':
          //logoUrl = logoBrokers;
          //linkUrl = "https://careers.mybrokersllc.com/";
        //break;          
      //case '/redpagoscavia':
          //logoUrl = logoRedpagos;
          //linkUrl = "https://redpagoscavia.com.uy/";
        //break;     
    default:
      logoUrl = logoMkt;
      linkUrl = "https://mktmarketingdigital.com";
  }

  const downloadLinks = {
    "mkt": "https://lookerstudio.google.com/reporting/7698c846-2dc4-4d53-b23a-7e8b23019f7b",
    "mde": "https://lookerstudio.google.com/reporting/ae2ae56a-efb7-4ad5-a119-da295e9a0cb6",
    "cursomktinmobiliario": "https://lookerstudio.google.com/reporting/ae2ae56a-efb7-4ad5-a119-da295e9a0cb6",
    "misparrillas": "https://lookerstudio.google.com/reporting/e1707dc1-8ec2-4728-885f-6320b283caee",
    "habitatgroup": "https://lookerstudio.google.com/reporting/4f26bea3-890e-458a-b4ff-89bbf694fd3f",
    "EB-5": "https://lookerstudio.google.com/reporting/e27efe0b-471c-43b3-b928-49e53f5ea537",
    "smartbrickell": "https://lookerstudio.google.com/reporting/cf3ac275-8d81-4a58-8043-dc45cc546516",
    "smartbrickellhotel": "https://lookerstudio.google.com/reporting/97afb7c3-5c6f-4c57-b34c-64d88889a216",
    "redpagos": "https://lookerstudio.google.com/reporting/35a21e02-53c6-4716-9ee6-e2bbe11c506d",
    "volveraescuchar": "https://lookerstudio.google.com/reporting/54eed364-5fba-4927-800b-370a9a23d774",
    "baoworkcenter": "https://lookerstudio.google.com/reporting/be2cf3a6-74cd-4e08-9ebe-b0e520b450b5",
    "drjones": "https://lookerstudio.google.com/reporting/3d7a29ef-47c0-487a-8dca-a4a03e1097d6",
    "benozzi": "https://lookerstudio.google.com/reporting/98fac6a5-f7a2-4117-a4d5-c40aa74a72c3",
    "puntaballena": "https://lookerstudio.google.com/reporting/ad43c284-de0e-4985-9757-d0d1ebad3fe1",
    "tgc": "https://lookerstudio.google.com/reporting/6f344866-3b1a-4664-876b-13997f068358",
    "dracignetti": "https://lookerstudio.google.com/reporting/d9b9fc9b-6936-4d9e-b4b7-91755120234f",
    "beautyderm": "https://lookerstudio.google.com/reporting/90d0dba8-b8a7-45ae-baef-20ab822e9ec6",
    "moscow": "https://lookerstudio.google.com/reporting/92830d69-fe43-4e91-acb9-e19ad8be9e93",
    "xyz": "https://lookerstudio.google.com/reporting/7698c846-2dc4-4d53-b23a-7e8b23019f7b"
  };

  return (
    <div className="sidebar">
      <div className="mkt-section">
        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
          <img src={logoUrl} alt="Logo" className="logo-sidebar" />
        </a>
      </div>
      <div className="center">
        <ul>
          <NavLink to={links.PanelGeneral} activeClassName="active" style={{ textDecoration: "none" }}>
            <li className={activeItem === links.PanelGeneral ? 'active' : ''}>
              <DashboardIcon className="icon" />
              <span>Panel General</span>
            </li>
          </NavLink>
{/* <NavLink to={links.equipo} activeClassName="active" style={{ textDecoration: "none" }}> */}
{/*   {(basePath !== '/habitatgroup' && basePath !== '/smartbrickell' && basePath !== '/smartbrickellhotel' && basePath !== '/EB-5') && ( */}
{/*     <li className={activeItem === links.equipo ? 'active' : ''}> */}
{/*       <Diversity3Icon className="icon" /> */}
{/*       <span>Equipo</span> */}
{/*     </li>)} */}
{/* </NavLink> */}

          <NavLink to={links.googleAnalytics} activeClassName="active" style={{ textDecoration: "none" }}>
          {( basePath !== '/moscow') && (
            <li className={activeItem === links.googleAnalytics ? 'active' : ''}>
              <InsightsIcon className="icon" />
              <span>Google Analytics</span>
            </li>)}
          </NavLink>
          
          <NavLink to={links.googleAds} activeClassName="active" style={{ textDecoration: "none" }}>
          {(basePath !== '/smartbrickell' && basePath !== '/smartbrickellhotel' && basePath !== '/moscow') && (
           <li className={activeItem === links.googleAds ? 'active' : ''}>
           <AdsClickIcon className="icon" />
           <span>Google Ads</span>
         </li> )}
         </NavLink>


          <NavLink to={links.metaPanel} activeClassName="active" style={{ textDecoration: "none" }}>
          {(basePath !== '/smartbrickell' && basePath !== '/smartbrickellhotel' && basePath !== '/moscow') && (
            <li className={activeItem === links.metaPanel ? 'active' : ''}>
              <AllInclusiveIcon className="icon" />
              <span>Meta</span>
            </li>)}
          </NavLink>
          <NavLink to={links.seoPanel} activeClassName="active" style={{ textDecoration: "none" }}>
          {( basePath !== '/moscow') && (
            <li className={activeItem === links.seoPanel ? 'active' : ''}>
              <EditNoteIcon className="icon" />
              <span>SEO</span>
            </li>
            )}
          </NavLink>
        </ul>
      </div>
      <div className="flex-bottom">
        <div className="bottom-links">
          <ul>
          {(allowedRolesForHabitatSection.includes(role) && (basePath === '/habitatgroup' || basePath === '/EB-5' || basePath === '/smartbrickell' || basePath === '/smartbrickellhotel')) && (
            <NavLink to="/proyectos" activeClassName="active" style={{ textDecoration: "none" }}>
              <li className={activeItem === '/proyectos' ? 'active' : ''}>
                <SearchIcon className="icon" />
                <span>Proyectos</span>
              </li>
            </NavLink>
          )}
          {allowedRolesForClientesSection.includes(role) && (
            <NavLink to="/elegircliente" activeClassName="active" style={{ textDecoration: "none" }}>
              <li className={activeItem === '/elegircliente' ? 'active' : ''}>
                <PersonSearchSharpIcon className="icon" />
                <span>Clientes</span>
              </li>
            </NavLink>
          )}
          <NavLink to={links.Perfil} activeClassName="active" style={{ textDecoration: "none" }}>
            <li className={activeItem === links.Perfil ? 'active' : ''}>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Perfil</span>
            </li>
          </NavLink>
            <li style={{ cursor: 'pointer' }} onClick={handleLogout}>
              <ExitToAppIcon className="icon" />
              <span>Cerrar sesión</span>
            </li>
          </ul>
        </div>
        {allowedRolesForDownload.includes(role) && location.pathname.includes('/panelgeneral') && (
  <div className="download-report">
    <a href={downloadLinks[cliente]} target="_blank" rel="noopener noreferrer" className="download-link">
      <button className="download-button">Descargar Informe</button>
    </a>
  </div>
)}
      </div>
    </div>
  );
};

export default Sidebar;
